import React, {useState} from "react";
import * as players from './players'
import './Game.css'
import {Square} from "./Square";

export const Game = () => {
    const gridSize = [3, 3]
    const [currentPlayer, setCurrentPlayer] = useState(players.player1)
    const [winningPlayer, setWinningPlayer] = useState(null)
    const [draw, setDraw] = useState(false);

    const winner = (player) => {
        setWinningPlayer(player)
    }

    const togglePlayer = (player) => {
        setCurrentPlayer(player === players.player1 ? players.player2 : players.player1)
    }

    const generateGrid = (rows, cols, squareCreator) => Array(rows).fill([]).map((value, x) => Array(cols).fill(null).map((value, y) => squareCreator(x, y)))

    const createSquare = () => {
        return ({
            markedBy: null,
        });
    }

    const [grid, setGrid] = useState(generateGrid(...gridSize, createSquare))

    const GameSquare = ({x, y}) => {
        return grid[x][y].markedBy || winningPlayer
            ? <Square sign={grid[x][y].markedBy?.sign} disabled={true}/>
            : <Square handleClick={() => {
                markSquare(x, y, currentPlayer)
                togglePlayer(currentPlayer)
            }}/>
    }

    const markSquare = (x, y, player) => {
        console.log(`Square marked at (${x}, ${y})`);
        grid[x][y].markedBy = player


        if (isWinning(player)) {
            winner(player)
        } else if (isDraw()) {
            setDraw(true)
        }

        setGrid(grid)
    }

    const getCol = y => grid[y]
    const getRow = x => grid.map(y => y[x]);
    const getPrimaryDiagonal = () => grid.map((y, index) => y[index])
    const getSecondaryDiagonal = () => grid.map((y, index) => y[grid.length - index - 1])

    const isTupleMarkedBySamePlayer = (tuple, player) => tuple.filter(n => n.markedBy && n.markedBy.name === player.name).length === tuple.length
    const isTupleWonByPlayer = (player, tupleIndexes, getTuple) => tupleIndexes.reduce((isWinning, tupleIndex) => isWinning || isTupleMarkedBySamePlayer(getTuple(tupleIndex), player), false)

    const isWinning = player => {
        const [rows, cols] = [
            Array.from(Array(grid.length).keys()),
            Array.from(Array(grid[0].length).keys()),
        ]

        const horizontalWin = isTupleWonByPlayer(player, rows, rowIndex => getRow(rowIndex))
        const verticalWin = isTupleWonByPlayer(player, cols, colIndex => getCol(colIndex))
        const primaryDiagonalWin = isTupleWonByPlayer(player, cols, () => getPrimaryDiagonal())
        const secondaryDiagonalWin = isTupleWonByPlayer(player, cols, () => getSecondaryDiagonal())

        return horizontalWin || verticalWin || primaryDiagonalWin || secondaryDiagonalWin
    }

    const isDraw = () => !grid.some(row => row.some(square => square.markedBy === null))

    return (
        <>
            <h1>Tic Tac Toe</h1>
            {!winningPlayer && !draw && <h2>Current Player: {currentPlayer.name} ({currentPlayer.sign})</h2>}
            {winningPlayer && <h2 className={'winner'}>Winner: {winningPlayer.name} ({winningPlayer.sign})</h2>}
            {draw && <h2>Draw !</h2>}

            <table>
                <tbody>
                <tr>
                    <td><GameSquare x={0} y={0}/></td>
                    <td><GameSquare x={1} y={0}/></td>
                    <td><GameSquare x={2} y={0}/></td>
                </tr>
                <tr>
                    <td><GameSquare x={0} y={1}/></td>
                    <td><GameSquare x={1} y={1}/></td>
                    <td><GameSquare x={2} y={1}/></td>
                </tr>
                <tr>
                    <td><GameSquare x={0} y={2}/></td>
                    <td><GameSquare x={1} y={2}/></td>
                    <td><GameSquare x={2} y={2}/></td>
                </tr>
                </tbody>
            </table>
        </>
    )


}
